import React, {useContext, useEffect} from 'react';
import {Navigate, Route, Routes} from 'react-router-dom';
import is from 'is_js';
import MainLayout from 'layouts/MainLayout';
import SettingsToggle from 'components/settings-panel/SettingsToggle';
import SettingsPanel from 'components/settings-panel/SettingsPanel';

import ErrorLayout from 'layouts/ErrorLayout';
import Landing from 'components/pages/landing/Landing';
import {toast, ToastContainer} from 'react-toastify';
import {CloseButton} from 'components/common/Toast';


import Starter from 'components/pages/Starter';
import Profile from 'components/pages/user/profile/Profile';
import Settings from 'components/pages/user/settings/Settings';
import PrivacyPolicy from 'components/pages/miscellaneous/privacy-policy/PrivacyPolicy';


import Error404 from 'components/errors/Error404';
import Error500 from 'components/errors/Error500';
import InvalidCode from 'components/errors/invalid-customer-code';

import CardLogin from 'components/authentication/card/Login';
import Logout from 'components/authentication/card/Logout';
import CardRegistration from 'components/authentication/card/Registration';
import CardForgetPassword from 'components/authentication/card/ForgetPassword';
import CardConfirmMail from 'components/authentication/card/ConfirmMail';
import CardPasswordReset from 'components/authentication/card/PasswordReset';
import CardLockScreen from 'components/authentication/card/LockScreen';

import EnterCode from 'components/xenon/EnterCode';

import Dashboard from 'components/dashboards/default';
import Onboarding from 'components/dashboards/onboarding';
import Top from 'components/dashboards/landing';
import Slides from 'components/dashboards/guider';
import AppContext from 'context/Context';

const Layout = () => {
    const HTMLClassList = document.getElementsByTagName('html')[0].classList;
    useContext(AppContext);

    useEffect(() => {
        if (is.windows()) {
            HTMLClassList.add('windows');
        }
        if (is.chrome()) {
            HTMLClassList.add('chrome');
        }
        if (is.firefox()) {
            HTMLClassList.add('firefox');
        }
    }, [HTMLClassList]);

    return (
        <>
            <Routes>
                <Route path="landing" element={<Landing/>}/>
                <Route element={<ErrorLayout/>}>
                    <Route path="errors/404" element={<Error404/>}/>
                    <Route path="errors/500" element={<Error500/>}/>
                    <Route path="errors/invalid-code" element={<InvalidCode/>}/>
                </Route>
                {/*- ------------- Authentication ---------------------------  */}


                {/*- ------------- Card ---------------------------  */}
                <Route path="authentication/card/login" element={<CardLogin/>}/>
                <Route
                    path="authentication/card/register"
                    element={<CardRegistration/>}
                />
                <Route path="authentication/card/logout" element={<Logout/>}/>
                <Route
                    path="authentication/card/forgot-password"
                    element={<CardForgetPassword/>}
                />
                <Route
                    path="authentication/card/reset-password"
                    element={<CardPasswordReset/>}
                />
                <Route
                    path="authentication/card/confirm-mail"
                    element={<CardConfirmMail/>}
                />
                <Route
                    path="authentication/card/lock-screen"
                    element={<CardLockScreen/>}
                />

                {/* //--- MainLayout Starts  */}

                <Route element={<MainLayout/>}>
                    {/*Dashboard*/}
                    <Route path="analyzer-r" element={<Dashboard/>}/>

                    {/*Onboarding*/}
                    <Route path="onboarding" element={<Onboarding/>}/>

                    {/*Landing*/}
                    <Route path="discover-r" element={<Top/>}/>

                    {/*Guide-r*/}
                    <Route path="guide-r" element={<Slides/>}/>

                    {/*Pages*/}
                    <Route path="pages/starter" element={<Starter/>}/>
                    <Route path="user/profile" element={<Profile/>}/>
                    <Route path="user/settings" element={<Settings/>}/>
                    <Route
                        path="miscellaneous/privacy-policy"
                        element={<PrivacyPolicy/>}
                    />

                    {/*- ------------- Xenon ---------------------------  */}
                    <Route path="/" element={<EnterCode/>}/>
                    {/*- ------------- Xenon end -----------------------  */}

                </Route>

                {/* //--- MainLayout end  */}

                {/* <Navigate to="/errors/404" /> */}
                <Route path="*" element={<Navigate to="/errors/404" replace/>}/>
            </Routes>
            <SettingsToggle/>
            <SettingsPanel/>
            <ToastContainer
                closeButton={CloseButton}
                icon={false}
                position={toast.POSITION.BOTTOM_LEFT}
            />
        </>
    );
};

export default Layout;
