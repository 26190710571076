import React, {useContext, useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {useNavigate} from 'react-router-dom';
import AppContext from 'context/Context';
import ReactGoogleSlides from 'react-google-slides';
import apiRetrier from "../../../../api/api_retrier";
import CustomerApi from "../../../../api/customer";

const Slides = ({   customerApi_ = CustomerApi,
                    customer_ = '',
                    errorLog_ = console.log,
                    maxApiRetry_ = -1
                 }) => {
    const navigate = useNavigate();
    const [customer, setCustomer] = useState(customer_);
    const maxApiTime = 180000; //3 minutes

    const {
        config: {
            customerCode,
            apiUrl
        }
    } = useContext(AppContext);

    useEffect(() => {
        if (customerCode === '') {
            navigate('/');
            return;
        }
    }, [navigate, customerCode, apiUrl, errorLog_]);


    useEffect(() => {
        if (customerCode === 'demo') {
            setUpDemo();
            return;
        }

        if (customerCode) {
            let data = {token: customerCode};
            getCustomer(data);
        }

    }, [customerCode]);

    function getCustomer(data) {
        apiRetrier(() => customerApi_(apiUrl).fetch({data}), maxApiRetry_, maxApiTime)
          .then(response => {
              setCustomer(response);
          })
          .catch(error => {
              errorLog_(error);
          });
    }

    function setUpDemo() {
        setCustomer({
            name: "demo",
            email: "demo@email.com",
            google_slides: 'https://docs.google.com/presentation/d/1xzIA0VLDuhgi2lim0UV9_DD1orAxmZmYdkqvjNcIAkA/edit?usp=sharing'
        });
    }

    if (customerCode === '' || !customer.google_slides) return null;
    return (
      <ReactGoogleSlides
        width="100%"
        slidesLink={customer.google_slides}
        slideDuration={5}
        position={1}
        showControls
        loop
      />
    );
};
Slides.propTypes = {
    errorLog_: PropTypes.func,
};

export default Slides;
